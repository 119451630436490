import {useCallback, useRef, useState} from 'react'

import cn from 'classnames'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import {useClickAway} from 'react-use'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import Message2LineIcon from 'remixicon-react/Message2LineIcon'

import {useBambuser, useComparison} from '@festi/common/contexts'
import {styledTheme} from '@festi/common/themes'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'
import {onDutyFree} from '@festi/common/constants/channels'

import Expandable from '../common/Expandable'
import ChatOptions from './ChatOptions'

const disableVideo = !settings.bambuserVideo || onDutyFree

const BubbleWrapper = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: ${styledTheme.zIndex.chatBubble};
  transition: bottom 0.2s ease-out;

  &.isComparing {
    bottom: 136px;
  }

  &.scrollRowOpen {
    ${fluidRange('bottom', '88px', '100px')}
  }

  &.isComparing.scrollRowOpen {
    ${fluidRange('bottom', '210px', '222px')}
  }
`

const ImageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${fluidRange('width', '64px', '80px', 'xs', 'lg')}
  ${fluidRange('height', '64px', '80px', 'xs', 'lg')}
  background-color: ${palette('green')};
  border-radius: 50%;
  cursor: pointer;

  .remixicon-icon {
    width: 32px;
    height: 32px;
    fill: ${palette('white')};
  }
`

const AbsoluteWrapper = styled.div`
  position: fixed;
  right: 16px;
  ${fluidRange('bottom', '90px', '112px')}
  padding-left: 16px;
  z-index: ${styledTheme.zIndex.chatBubble};

  &.isComparing {
    ${fluidRange('bottom', '210px', '230px')}
  }

  &.scrollRowOpen {
    ${fluidRange('bottom', '160px', '192px')}
  }

  &.isComparing.scrollRowOpen {
    ${fluidRange('bottom', '290px', '318px')}
  }
`

interface BambuserQueueOpen {
  closingTime?: Date
  isOpen: boolean
  openingTime?: Date
}

interface Props {
  isOpen: boolean
  onClick: () => void
  sendTagManager: (event: string, label: string) => void
}

export default function LiveChatBubble({
  isOpen,
  onClick,
  sendTagManager,
}: Props) {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const [hasBeenShown, setHasBeenShown] = useState(false)

  const {isComparing} = useComparison()
  const {videoChatOpen, changeChatBubblePosition} = useBambuser()

  const openLiveChat = useCallback(() => {
    onClick()
    sendTagManager('open chat', 'chat')
  }, [onClick, sendTagManager])

  const onClickChat = useCallback(async () => {
    if (disableVideo) {
      openLiveChat()
    } else {
      if (hasBeenShown) {
        setOpen(!open)
      } else {
        if (videoChatOpen) {
          setOpen(true)
          setHasBeenShown(true)
          return
        } else {
          openLiveChat()
        }
      }
    }
  }, [open, hasBeenShown, videoChatOpen, openLiveChat])

  useClickAway(ref, () => {
    if (open) {
      setOpen(false)
    }
  })

  return (
    <div ref={ref}>
      <BubbleWrapper
        className={cn({
          isComparing,
          scrollRowOpen: changeChatBubblePosition,
        })}
      >
        <ImageIcon onClick={onClickChat} className={cn({isOpen})}>
          {open ? <CloseLineIcon /> : <Message2LineIcon />}
        </ImageIcon>
      </BubbleWrapper>

      <AbsoluteWrapper
        className={cn({
          isComparing,
          scrollRowOpen: changeChatBubblePosition,
        })}
      >
        <Expandable expanded={open}>
          <ChatOptions
            onOpenChat={onClick}
            sendTagManager={sendTagManager}
            onRequestClose={() => setOpen(false)}
          />
        </Expandable>
      </AbsoluteWrapper>
    </div>
  )
}
