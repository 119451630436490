var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bd538749f0ae544c6db9da34ec17ab13a164730e"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.RUNTIME_SENTRY_DSN,
  environment: process.env.RUNTIME_ENVIRONMENT?.toLowerCase(),

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: process.env.RUNTIME_DOMAIN?.toLowerCase() === 'localhost',

  replaysOnErrorSampleRate: 0.1,

  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  ignoreErrors: [
    // Facebook pixel error
    /Can't find variable: fbq/i,
    /fbq is not defined/i,
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,

    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],

  beforeSend(event, hint) {
    const error = hint.originalException
    // ignore typescript errors
    // @ts-ignore
    if (error && error.stack) {
      // @ts-ignore
      const stack = error.stack
      const isDroppError = stack.match(/app.dropp.is/i)
      if (isDroppError) {
        return null
      }
    }
    return event
  },
})
