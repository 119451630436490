import {useCallback} from 'react'

import styled from 'styled-components'
import Chat3LineIcon from 'remixicon-react/Chat3LineIcon'

import {MainButton} from '@festi/common/components/buttons'
import {H4, P} from '@festi/common/components/typography'
import VideoCall from '@festi/common/components/chat/VideoCall'
import {styledTheme} from '@festi/common/themes'

const Root = styled.div`
  max-width: 634px;
  border-radius: ${styledTheme.borderRadius.large};
  background-color: white;
  box-shadow: ${styledTheme.boxShadow.modal};
  overflow: hidden;
`

const ImageCover = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  object-position: top;
`

const Container = styled.div`
  padding: 24px;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 24px;
  height: 100%;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  height: 100%;
`

interface Props {
  onOpenChat: () => void
  onRequestClose: () => void
  sendTagManager: (event: string, label: string) => void
}

export default function ChatOptions({
  onOpenChat,
  onRequestClose,
  sendTagManager,
}: Props) {
  const onClickChat = useCallback(() => {
    onRequestClose()
    sendTagManager('open chat', 'chat')
    onOpenChat()
  }, [onOpenChat, onRequestClose, sendTagManager])

  return (
    <Root>
      <ImageCover src="/images/bambuser-staff.webp" alt="Staff" />

      <Container>
        <H4 withGutter>Hvernig getum við aðstoðað?</H4>

        <ContentWrapper>
          <Item>
            <P>
              Myndsímtal við sölufulltrúa í verslun. Þú sérð okkur, en ræður
              hvort við sjáum þig. Opnunartími 10–18 alla virka daga.
            </P>

            <VideoCall
              onClick={() => {
                onRequestClose()
                sendTagManager('open videochat', 'videochat')
              }}
            />
          </Item>

          <Item>
            <P>Netspjall við starfsmann í þjónustuveri.</P>
            <MainButton
              label="Netspjall"
              suffixIcon={Chat3LineIcon}
              fullWidth
              onClick={onClickChat}
            />
          </Item>
        </ContentWrapper>
      </Container>
    </Root>
  )
}
