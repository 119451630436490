import {createContext, useCallback, useContext, useState} from 'react'

import TagManager from 'react-gtm-module'
import {LiveChatWidget} from '@livechat/widget-react'

import {useAuth} from './UserContext'
import settings from '../constants/settings'
import LiveChatBubble from '../components/chat/LiveChatBubble'

const license = '18817566'
const liveChat = settings.liveChat

export const LiveChatContext = createContext<Partial<LiveChatContextProps>>({})

interface LiveChatProviderProps {
  children: React.ReactNode
}

export function LiveChatProvider({children}: LiveChatProviderProps) {
  const {user} = useAuth()

  const [open, setOpen] = useState(false)
  const [available, setAvailable] = useState(false)

  const sendTagManager = useCallback((event: string, label: string) => {
    TagManager.dataLayer({
      dataLayer: {
        category: 'communication',
        event,
        event_label: label,
      },
      dataLayerName: 'dataLayer',
    })
  }, [])

  return (
    <LiveChatContext.Provider
      value={{
        license,
      }}
    >
      {children}

      {liveChat && (
        <>
          {available && (
            <LiveChatBubble
              isOpen={open}
              sendTagManager={sendTagManager}
              onClick={() => setOpen(true)}
            />
          )}

          <LiveChatWidget
            license={license}
            customerName={user?.name}
            customerEmail={user?.email}
            visibility={open ? 'maximized' : 'hidden'}
            onReady={() => setAvailable(true)}
            onVisibilityChanged={({visibility}) => {
              if (visibility !== 'maximized') {
                setOpen(false)
              }
            }}
            onAvailabilityChanged={({availability}) => {
              if (availability === 'online') {
                setAvailable(true)
              }
            }}
          />
        </>
      )}
    </LiveChatContext.Provider>
  )
}

export interface LiveChatContextProps {
  license: string
}

export function useLiveChatContext() {
  return useContext(LiveChatContext) as LiveChatContextProps
}
