/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddInsuranceRequest {
  variantSku: string
  insuranceSku: string
}

export interface Address {
  id: number
  name: string
  ssn: string
  companyName?: string
  streetAddress1?: string
  streetAddress2?: string
  postalCode: PostalCode
  phone: string
}

export interface AddressRequest {
  companyName?: string
  streetAddress1?: string
  streetAddress2?: string
}

export interface AppleHeaderRequest {
  publicKeyHash: string
  ephemeralPublicKey: string
  transactionId: string
  applicationData?: string
  wrappedKey?: string
}

export interface ApplePaymentDataRequest {
  version: string
  data: string
  signature: string
  header: AppleHeaderRequest
}

export interface ApplePaymentMethodRequest {
  network: string
  type: string
  displayName: string
}

export interface AppleTokenCreateRequest {
  token: ApplePaymentDataRequest
  paymentMethod: ApplePaymentMethodRequest
  transactionIdentifier: string
}

export interface AppleTokenResponse {
  token: string
}

export interface AvailableInsurance {
  name: string
  sku: string
  months: number

  /** @format double */
  price: number
}

export interface AvailableInsuranceRequest {
  name: string
  sku: string
  months: number

  /** @format double */
  price: number
}

export interface AvailableInsurances {
  sku: string
  insurance: AvailableInsurance[]
}

export interface AvailableInsurancesRequest {
  sku: string
  insurance: AvailableInsuranceRequest[]
}

export interface Business {
  id: number

  /** Kennitala */
  ssn: string

  /** Nafn */
  name: string

  /** Um fyrirtækið */
  about?: string
  invoicingAllowed: boolean
  members: BusinessMember[]
  invoicingEmails: BusinessInvoicingEmail[]
  contactPhoneNumber?: string | null
  defaultBillingAddress: Address
}

export interface BusinessInvoicingEmail {
  /** @format uuid */
  id: string

  /** @format email */
  email: string
}

export interface BusinessInvoicingEmailRequest {
  /** @format email */
  email: string
}

export interface BusinessMember {
  id: number
  user: BusinessMemberUser | null

  /** Nafn */
  name: string

  /** Kennitala */
  ssn?: string

  /** Hlutverk */
  role?: BusinessMemberRoleEnum
}

export interface BusinessMemberRequest {
  /** Kennitala */
  ssn?: string

  /** Hlutverk */
  role?: BusinessMemberRoleEnum
}

export enum BusinessMemberRoleEnum {
  PowerOfAttorney = 'power of attorney',
  Admin = 'admin',
  PurchasingPrivilege = 'purchasing privilege',
  ViewingPrivilege = 'viewing privilege',
}

export interface BusinessMemberUser {
  email: string
  name: string
  phoneNumber: string
  avatar: {default: string}
}

export interface BusinessMemberUserRequest {
  name: string
  avatar: {default: File}
}

export interface BusinessRegistry {
  name: string
}

export interface BusinessRequest {
  /** Nafn */
  name: string

  /** Um fyrirtækið */
  about?: string
  contactPhoneNumber?: string | null
  defaultBillingAddress: AddressRequest
}

export interface Category {
  id: number
  name: string

  /** Þolfall */
  nameTf?: string

  /** @pattern ^[-\w]+$ */
  slug: string
  descriptionPlaintext?: string
  description?: Record<string, any>
  parent?: number | null
  backgroundImage: {
    webpXs?: string
    webpS?: string
    webpL?: string
    jpgXs?: string
    jpgS?: string
    jpgL?: string
  }
  backgroundImageAlt?: string
  index: number
  seoTitle?: string | null
  seoDescription?: string | null
  productCount: number
}

export enum ChallengeCancelEnum {
  Type01 = '01',
  Type02 = '02',
  Type03 = '03',
  Type04 = '04',
  Type05 = '05',
  Type06 = '06',
  Type07 = '07',
}

export enum ChallengeIndicatorEnum {
  Type01 = '01',
  Type02 = '02',
  Type03 = '03',
  Type04 = '04',
  Type90 = '90',
}

export interface Channel {
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
  name: string
}

export interface ChannelRequest {
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
  name: string
}

export interface Checkout {
  /** @format uuid */
  token: string
  shippingAddress: Address
  billingAddress: Address

  /** @format email */
  email: string
  lines: CheckoutLine[]
  delivery: Delivery
  postalCode: string
  shippingMethod: number | null
  quantity: number
  totalPrice: number
  shippingPrice: number
  subTotalPrice: number
  discount: number
  voucherCode: string
  note?: string
  availableShippingMethods: ShippingMethodChannelListing[]
  availableInsurances: AvailableInsurances[]
  availablePostalCodes: PostalCode[]
  defaultPostalCode: PostalCode
  availablePaymentGateways: PaymentGateway[]
  isVuv: boolean
  loanOptions: Record<string, any>
}

export interface CheckoutDeliveryRequest {
  slot?: number | null

  /** @format date */
  date?: string
  metadata?: Record<string, any>
}

export interface CheckoutLine {
  id: number
  variant: VariantList
  insurances: CheckoutLineInsurance[]
  totalPrice: number
  quantity: number
  quantityIsAvailable: boolean
}

export interface CheckoutLineInsurance {
  /** @format uuid */
  id: string
  name: string
  sku: string

  /**
   * @min 0
   * @max 2147483647
   */
  months: number

  /**
   * @min 0
   * @max 2147483647
   */
  price: number
}

export interface CheckoutMergeRequest {
  /** @format uuid */
  token: string
}

export interface CheckoutRequest {
  shippingAddress: AddressRequest
  billingAddress: AddressRequest
  delivery: DeliveryRequest
  postalCode: string
  shippingMethod: number | null
  note?: string
}

export interface CheckoutSalesType {
  warehouse: string
  sku: string
  variantName: string
  quantity: number
  showNotification: boolean
}

export enum CodeEnum {
  INVALID_AUTH = 'INVALID_AUTH',
  SSN_MISMATCH = 'SSN_MISMATCH',
  SSN_VALIDATION = 'SSN_VALIDATION',
  NO_PROCUREMENT = 'NO_PROCUREMENT',
}

export interface Coordinates {
  /** @format double */
  x: number

  /** @format double */
  y: number
}

export interface CreateAddress {
  name: string
  ssn?: string
  companyName?: string
  streetAddress1: string
  streetAddress2?: string
  postalCode: string
  phone: string
}

export interface CreateAddressRequest {
  name: string
  ssn?: string
  companyName?: string
  streetAddress1: string
  streetAddress2?: string
  postalCode: string
  phone: string
}

export interface CreateBusinessError {
  code: CodeEnum
  details: string
}

export interface CreateBusinessMemberRequest {
  ssn: string
  role: CreateBusinessMemberRoleEnum

  /** @format email */
  email?: string | null
}

export enum CreateBusinessMemberRoleEnum {
  Admin = 'admin',
  PurchasingPrivilege = 'purchasing privilege',
  ViewingPrivilege = 'viewing privilege',
}

export interface CreateBusinessRequest {
  ssn: string

  /** @format email */
  invoicingEmail?: string

  /** @format email */
  contactEmail?: string
  contactPhoneNumber?: string | null
  scaToken: string
}

export interface Credit {
  customerSsn: string

  /** @min 0 */
  creditLimit: number

  /** @min 0 */
  creditRemaining: number

  /** @format date-time */
  timestamp: string
}

export interface CreditApplication {
  id: number
  approved: boolean
  creditLimit: string

  /** @min 1 */
  amountAppliedFor?: number
  maxCreditAmount: number | null

  /** @format email */
  applicantEmail?: string
}

export interface CreditApplicationRequest {
  /** @min 1 */
  amountAppliedFor?: number

  /** @format email */
  applicantEmail?: string
}

export interface Delivery {
  id: number

  /** @format uuid */
  checkout: string
  order?: number | null
  fulfillment: number | null
  slot?: number | null

  /** @format date */
  date?: string
  metadata?: Record<string, any>
}

export interface DeliveryRequest {
  /** @format uuid */
  checkout: string
  order?: number | null
  slot?: number | null

  /** @format date */
  date?: string
  metadata?: Record<string, any>
}

export interface DeliverySlot {
  id: number
  name?: string
  description?: string
  shippingMethods?: number[]
  pickupLocation?: number | null
  weekdays?: (WeekdaysEnum | NullEnum | null)[]

  /** @format time */
  timeStart: string

  /** @format time */
  timeStop: string
  availability: Record<string, any>
}

export enum DeviceChannelEnum {
  SDK = 'SDK',
  BROWSER = 'BROWSER',
}

export interface DutyFreeCheckoutInfo {
  /** @format uuid */
  checkoutToken: string
  flightNumber: string

  /** @format date */
  flightDate: string
}

export interface DutyFreeCheckoutInfoRequest {
  flightNumber: string

  /** @format date */
  flightDate: string
}

export enum EciFlagEnum {
  Type05 = '05',
  Type06 = '06',
  Type07 = '07',
  Type02 = '02',
  Type01 = '01',
  Type00 = '00',
}

export type ElkoAttributeBanner = string

export type ElkoAttributeImportantInfo = string

export type ElkoAttributeNavEmm = boolean

export type ElkoAttributeOutlet = boolean

export interface ElkoPrice {
  /**
   * @min 0
   * @max 2147483647
   */
  price: number

  /**
   * @min 0
   * @max 2147483647
   */
  discountedPrice?: number | null
  lowestPrice: number
  discountAmount: number
}

export interface ElkoPriceRequest {
  /**
   * @min 0
   * @max 2147483647
   */
  price: number

  /**
   * @min 0
   * @max 2147483647
   */
  discountedPrice?: number | null
}

export interface ElkoUser {
  id: number
  email?: string
  name: string
  ssn: string
  phoneNumber?: string | null
  isStaff: boolean
  avatar: {default: string}

  /**
   * Síðasta innskráning
   * @format date-time
   */
  lastLogin: string | null
  profile: number
  generalTermsAccepted: boolean
  personalizationAccepted: boolean
  postlistAccepted: boolean
  canViewSsnReceipts: boolean
  needsContactInfoReview: boolean
  isEmployee: string | null
}

export interface ElkoUserRequest {
  email?: string
  phoneNumber?: string | null
}

export interface EmailRequest {
  /** @format email */
  email?: string
}

export interface EmailRequestSerialierRequest {
  /** @format email */
  email?: string
}

export enum EnrolledEnum {
  Y = 'Y',
  N = 'N',
  U = 'U',
  B = 'B',
}

export interface FieldType {
  key: string
  index: number
  label: string
  category: string
  multiValue: boolean
  info: string
  isProperty: boolean
}

export interface FlashShelfLabelRequest {
  warehouseSlug: string
}

export interface GoogleIntermediateSeriailzerRequest {
  signedKey: string
  signatures: string[]
}

export interface GoogleTokenCreate {
  token: string
}

export interface GoogleTokenCreateRequest {
  protocolVersion: string
  signature: string
  intermediateSigningKey: GoogleIntermediateSeriailzerRequest
  signedMessage: string
}

export enum InterfaceEnum {
  DEFAULT = 'DEFAULT',
  DROPP = 'DROPP',
  POSTBOX = 'POSTBOX',
  DELIVERY_SLOT = 'DELIVERY_SLOT',
  SOTTELKO = 'SOTTELKO',
}

export interface Login {
  user: User
  cognito: LoginCognito
  saleor: LoginSaleor
}

export interface LoginCognito {
  accessToken: string
  refreshToken: string
}

export interface LoginCognitoRequest {
  accessToken: string
  refreshToken: string
}

export interface LoginRequest {
  username: string
  password: string
}

export interface LoginSaleor {
  csrfToken: string
  accessToken: string
  refreshToken: string
}

export interface LoginSaleorRequest {
  csrfToken: string
  accessToken: string
  refreshToken: string
}

export interface MailListSignup {
  status: StatusE96Enum
}

export interface MailListSignupRequest {
  allowMarketing: boolean
}

export enum NullEnum {
  null = null,
}

export interface Order {
  id: number

  /** @format date-time */
  created: string
  status?: OrderStatusEnum

  /** @format uuid */
  token: string
  checkoutToken?: string

  /** @format double */
  total: number

  /** @format double */
  undiscountedTotal: number

  /** @format double */
  totalPaid: number

  /** @format double */
  shippingPrice: number
  voucher: VoucherSerialzer
  customerNote?: string
  lines: OrderLine[]
  deliveries: Delivery[]
  billingAddress: Address
  shippingAddress: Address
  shippingMethodName: string | null
  payment: OrderPayment
}

export interface OrderLine {
  id: number
  variant: VariantList

  /**
   * @min 1
   * @max 2147483647
   */
  quantity: number
  insurances: OrderLineInsurance[]
  totalPrice: number
}

export interface OrderLineInsurance {
  /** @format uuid */
  id: string
  name: string
  sku: string

  /**
   * @min 0
   * @max 2147483647
   */
  months: number

  /**
   * @min 0
   * @max 2147483647
   */
  price: number
}

export interface OrderPayment {
  gateway: string
  ccFirstDigits?: string
  ccLastDigits?: string
  ccBrand?: string
}

export enum OrderStatusEnum {
  Draft = 'draft',
  Unconfirmed = 'unconfirmed',
  Unfulfilled = 'unfulfilled',
  PartiallyFulfilled = 'partially fulfilled',
  PartiallyReturned = 'partially_returned',
  Returned = 'returned',
  Fulfilled = 'fulfilled',
  Canceled = 'canceled',
}

export interface PaginatedBusinessList {
  /** @example 123 */
  count?: number

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=4
   */
  next?: string | null

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=2
   */
  previous?: string | null
  results?: Business[]
}

export interface PaginatedCheckoutList {
  /** @example 123 */
  count?: number

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null
  results?: Checkout[]
}

export interface PaginatedOrderList {
  /** @example 123 */
  count?: number

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=400&limit=100
   */
  next?: string | null

  /**
   * @format uri
   * @example http://api.example.org/accounts/?offset=200&limit=100
   */
  previous?: string | null
  results?: Order[]
}

export interface PaginatedProductListList {
  /** @example 123 */
  count?: number

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=4
   */
  next?: string | null

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=2
   */
  previous?: string | null
  results?: ProductList[]
}

export interface PaginatedUserPriceList {
  /** @example 123 */
  count?: number

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=4
   */
  next?: string | null

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=2
   */
  previous?: string | null
  results?: UserPrice[]
}

export interface PaginatedVariantListList {
  /** @example 123 */
  count?: number

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=4
   */
  next?: string | null

  /**
   * @format uri
   * @example http://api.example.org/accounts/?page=2
   */
  previous?: string | null
  results?: VariantList[]
}

export interface PagoLoanCalculation {
  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  amount: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  totalAmount: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  loanFeeAmount: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  totalInterests: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  totalFees: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  averageMonthlyPayment: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  interestRate: string
  installments: number

  /**
   *  a p r
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,4})?$
   */
  APR: string
  payments: PagoPayment[]
}

export interface PagoPayment {
  month: number

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  principalAmount: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  interestAmount: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  installmentFeeAmount: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  paymentFeeAmount: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  totalPaymentAmount: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  balanceAmount: string

  /** @format date */
  paymentDate: string
}

export enum ParesStatusEnum {
  Y = 'Y',
  N = 'N',
  U = 'U',
  A = 'A',
  C = 'C',
  R = 'R',
}

export interface PatchedBusinessMemberRequest {
  /** Kennitala */
  ssn?: string

  /** Hlutverk */
  role?: BusinessMemberRoleEnum
}

export interface PatchedBusinessRequest {
  /** Nafn */
  name?: string

  /** Um fyrirtækið */
  about?: string
  contactPhoneNumber?: string | null
  defaultBillingAddress?: AddressRequest
}

export interface PatchedCheckoutRequest {
  shippingAddress?: AddressRequest
  billingAddress?: AddressRequest
  delivery?: DeliveryRequest
  postalCode?: string
  shippingMethod?: number | null
  note?: string
}

export interface PatchedCreditApplicationRequest {
  /** @min 1 */
  amountAppliedFor?: number

  /** @format email */
  applicantEmail?: string
}

export interface PatchedElkoUserRequest {
  email?: string
  phoneNumber?: string | null
}

export interface PatchedEmailRequest {
  /** @format email */
  email?: string
}

export interface PatchedUpdateAddressRequest {
  name?: string
  ssn?: string
  companyName?: string
  streetAddress1?: string
  streetAddress2?: string
  postalCode?: string
  phone?: string
}

export interface PatchedWishlistItemRequest {
  comment?: string
}

export interface PatchedWishlistRequest {
  channel?: string
  name?: string
}

export interface PaymentGateway {
  id: string
  name: string
  options: string[]
  urls: Record<string, string>
}

export interface PickupLocation {
  id: number

  /** @pattern ^[-\w]+$ */
  slug: string

  /** Nefnifall */
  name: string

  /** Þágufall */
  nameTgf?: string

  /** Upplýsingar um hvar viðskiptavinur sækir vörur á þessum stað */
  directions?: string
  address?: string
  city?: string
  postalCode?: string
  externalLocationId: string
}

export interface PostalCode {
  code: string
  name: string
}

export interface Postbox {
  postboxId: string
  name: string
  address: string
  postcode: string
  town: string
  latitude: string
  longitude: string
}

export interface ProductChannelListing {
  /** @format date */
  availableForPurchase?: string | null
  channel: Channel
}

export interface ProductChannelListingMapping {
  webshop: ProductChannelListing
  dutyfree: ProductChannelListing
}

export interface ProductDetail {
  id: number

  /** @pattern ^[-\w]+$ */
  slug: string
  type: string
  name: string
  shortDescription: string
  firstImage: VariantImage
  categories: number[]

  /** @format date-time */
  updatedAt: string
  chargeTaxes?: boolean
  weight?: string | null
  sellingPoints: string[]

  /** @format double */
  online: number
  listings: ProductChannelListingMapping
  workshops: ProductWorkshop[]
  description: string
  collectAtWarehouse: boolean
  attributes: Record<string, any>
  variants: ProductVariant[]
}

export interface ProductList {
  id: number

  /** @pattern ^[-\w]+$ */
  slug: string
  type: string
  name: string
  shortDescription: string
  firstImage: VariantImage
  categories: number[]

  /** @format date-time */
  updatedAt: string
  chargeTaxes?: boolean
  weight?: string | null
  sellingPoints: string[]

  /** @format double */
  online: number
  listings: ProductChannelListingMapping
  workshops: ProductWorkshop[]
}

export interface ProductType {
  id: number
  name: string

  /** @pattern ^[-\w]+$ */
  slug: string
  hasVariants?: boolean
  isShippingRequired?: boolean
  isDigital?: boolean
}

export interface ProductVariant {
  sku?: string | null
  name?: string
  attributes: Record<string, any>
}

export interface ProductVariantChannelListing {
  channel: Channel
  visible: boolean
  price: ElkoPrice
}

export interface ProductVariantChannelListingDetail {
  channel: Channel
  visible: boolean
  price: ElkoPrice
  active: boolean
}

export interface ProductVariantChannelListingDetailMapping {
  webshop: ProductVariantChannelListingDetail
  dutyfree: ProductVariantChannelListingDetail
}

export interface ProductVariantChannelListingMapping {
  webshop: ProductVariantChannelListing
  dutyfree: ProductVariantChannelListing
}

export interface ProductWorkshop {
  displayName?: string | null
  description?: string | null
  workshopId?: string | null
  phoneNumber?: string | null
  ssn?: string | null

  /** @format email */
  email: string
  address: string
  zipCode?: string | null
  city: string

  /** @format uri */
  url: string
  isActive?: boolean
  webEnabled?: boolean | null

  /**
   * @min -2147483648
   * @max 2147483647
   */
  sortIndex?: number | null
  mapShareLink?: string | null
}

export interface Registry {
  valid: boolean
  ssn: string
}

export interface RestockSubscription {
  subscribed: boolean
}

export interface ShelfLabel {
  store: string
  locations: Coordinates[]
}

export interface ShippingMethod {
  id: number
  name: string
  type: ShippingMethodTypeEnum
  shippingZone: number
  minimumOrderWeight?: string | null
  maximumOrderWeight?: string | null
  postCodes: number[]
  interface: InterfaceEnum
  description: string

  /**
   * @min 0
   * @max 2147483647
   */
  sorting: number
  allowReturnSlips: boolean
}

export interface ShippingMethodChannelListing {
  id: number
  shippingMethod: ShippingMethod
  channel: Channel
  minimumOrderPrice: string | null
  maximumOrderPrice: string | null
  currency: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,9}(?:\.\d{0,3})?$
   */
  priceAmount?: string
  price: string
}

export interface ShippingMethodChannelListingRequest {
  shippingMethod: ShippingMethodRequest
  channel: ChannelRequest
  currency: string

  /**
   * @format decimal
   * @pattern ^-?\d{0,9}(?:\.\d{0,3})?$
   */
  priceAmount?: string
}

export interface ShippingMethodRequest {
  name: string
  type: ShippingMethodTypeEnum
  shippingZone: number
  minimumOrderWeight?: string | null
  maximumOrderWeight?: string | null
}

export enum ShippingMethodTypeEnum {
  Price = 'price',
  Weight = 'weight',
}

export interface Showcase {
  /** @pattern ^[-\w]+$ */
  slug: string
  name: string
}

export enum SignatureVerificationEnum {
  Y = 'Y',
  N = 'N',
}

export enum StatusE96Enum {
  Success = 'success',
}

export enum SubscriptionStatusEnum {
  InStock = 'in_stock',
  EndOfLife = 'end_of_life',
  Subscribable = 'subscribable',
}

export interface SyncMessage {
  status: StatusE96Enum
}

export interface SyncMessageRequest {
  properties: Record<string, any>
  content: Record<string, any>
}

export interface Terms {
  generalTerms?: boolean
  personalization?: boolean
  postlist?: boolean
}

export interface TermsRequest {
  generalTerms?: boolean
  personalization?: boolean
  postlist?: boolean
}

export interface ThreeDAuthenticationAdditionalDataRequest {
  totalItems: number
  deviceChannel: DeviceChannelEnum
  statusReason: string
  challengeIndicator: ChallengeIndicatorEnum
  challengeCancel: ChallengeCancelEnum
}

export interface ThreeDAuthenticationRequest {
  eciFlag: EciFlagEnum
  enrolled: EnrolledEnum
  cavv: string
  cavvAlgorithm?: string
  paresStatus: ParesStatusEnum
  threedsVersion: ThreedsVersionEnum
  dsTransactionId: string
  signatureVerification: SignatureVerificationEnum
  verification?: VerificationEnum[]
  errorDesc: string
  errorNo: string
  authenticationAmount?: number
  additionalData?: ThreeDAuthenticationAdditionalDataRequest
}

export enum ThreedsVersionEnum {
  Type102 = '1.0.2',
  Type210 = '2.1.0',
  Type220 = '2.2.0',
}

export interface TokenCreate {
  token: string
}

export interface TokenCreateRequest {
  cardNumber?: string
  expMonth?: number
  expYear?: number
  cvc?: string

  /** @format uuid */
  virtualCardId?: string
  store?: boolean
}

export interface TokenLoginRequest {
  accessToken: string
  refreshToken: string
}

export interface Turnover {
  year: string
  gross: number
  net: number
}

export interface UpdateAddress {
  name?: string
  ssn?: string
  companyName?: string
  streetAddress1?: string
  streetAddress2?: string
  postalCode?: string
  phone?: string
}

export interface UpdateAddressRequest {
  name?: string
  ssn?: string
  companyName?: string
  streetAddress1?: string
  streetAddress2?: string
  postalCode?: string
  phone?: string
}

export interface User {
  id: number
  email?: string
  name: string
  ssn: string
  phoneNumber?: string | null
  isStaff: boolean
  avatar: {default: string}

  /**
   * Síðasta innskráning
   * @format date-time
   */
  lastLogin: string | null
  profile: number
  generalTermsAccepted: boolean
  personalizationAccepted: boolean
  postlistAccepted: boolean
  canViewSsnReceipts: boolean
  needsContactInfoReview: boolean
}

export interface UserBusinessRequest {
  businessSsn?: string | null
}

export interface UserPrice {
  sku: string
  price: ElkoPrice
  userPrice: number
  lowestPrice: number
}

export interface UserRequest {
  email?: string
  phoneNumber?: string | null
}

export interface UserVerifyRequest {
  phone: string
}

export interface Variant {
  id: number
  sku?: string | null
  name?: string
  weight?: string | null
  trackInventory?: boolean
  sortOrder: number | null
  recommended: ElkoAttributeNavEmm
  outlet: ElkoAttributeOutlet
  banner: ElkoAttributeBanner
  images: VariantImage[]
  energyLabelImages: VariantImage[]
  showcases: Showcase[]
  inventoryStatus: Record<string, any>
  shelfLabels: ShelfLabel[]
  attributes: Record<string, any>
  importantInfo: ElkoAttributeImportantInfo
  subscriptionStatus: SubscriptionStatusEnum
  isInStockWeb: boolean
  isInStockDutyfree: boolean
  listings: VariantChannelListingMapping
  productId: number
  warrantyYears: number
}

export interface VariantChannelListing {
  channel: Channel
  visible: boolean
  price: ElkoPrice
  active: boolean
}

export interface VariantChannelListingMapping {
  webshop: VariantChannelListing
  dutyfree: VariantChannelListing
}

export interface VariantDetail {
  id: number
  sku?: string | null
  name?: string
  recommended: ElkoAttributeNavEmm
  outlet: ElkoAttributeOutlet
  banner: ElkoAttributeBanner
  images: VariantImage[]
  weight?: string | null
  trackInventory?: boolean
  showcases: Showcase[]
  inventoryStatus: Record<string, any>
  shelfLabels: ShelfLabel[]
  sortOrder: number | null
  attributes: Record<string, any>
  product: VariantProductDetail
  energyLabelImages: VariantImage[]
  importantInfo: ElkoAttributeImportantInfo
  subscriptionStatus: SubscriptionStatusEnum
  listings: ProductVariantChannelListingDetailMapping
  isInStockWeb: boolean
  isInStockDutyfree: boolean
  warrantyYears: number | null
}

export interface VariantImage {
  id: number
  image: {
    webpXs?: string
    webpS?: string
    webpL?: string
    jpgXs?: string
    jpgS?: string
    jpgL?: string
    productGallery?: string
    productGallery2x?: string
    productList?: string
    productSmall?: string
  }
  alt?: string
  sortOrder: number | null
}

export interface VariantList {
  id: number
  sku?: string | null
  name?: string
  categories: number[]
  firstImage: VariantImage
  trackInventory?: boolean
  inventoryStatus: Record<string, any>
  recommended: ElkoAttributeNavEmm
  outlet: ElkoAttributeOutlet
  banner: ElkoAttributeBanner
  product: VariantProductList
  listings: ProductVariantChannelListingMapping
  isInStockWeb: boolean
  isInStockDutyfree: boolean
  warrantyYears: number | null
}

export interface VariantPriceHistory {
  /** @format date */
  date: string
  price: number
  discountedPrice: number
}

export interface VariantProductDetail {
  id: number

  /** @pattern ^[-\w]+$ */
  slug: string
  type: string
  name: string
  shortDescription: string
  categories: number[]

  /** @format date-time */
  updatedAt: string
  chargeTaxes?: boolean
  weight?: string | null
  sellingPoints: string[]

  /** @format double */
  online: number
  listings: ProductChannelListingMapping
  workshops: ProductWorkshop[]
  description: string
  collectAtWarehouse: boolean
  attributes: Record<string, any>
}

export interface VariantProductList {
  id: number

  /** @pattern ^[-\w]+$ */
  slug: string
  type: string
  name: string
  shortDescription: string
  categories: number[]

  /** @format date-time */
  updatedAt: string
  chargeTaxes?: boolean
  weight?: string | null
  sellingPoints: string[]

  /** @format double */
  online: number
  listings: ProductChannelListingMapping
  workshops: ProductWorkshop[]
}

export enum VerificationEnum {
  DENIED = 'DENIED',
  NON_PARTICIPATION = 'NON_PARTICIPATION',
  UNABLE_TO_AUTHENTICATE = 'UNABLE_TO_AUTHENTICATE',
  MERCHANT_NOT_ENROLLED = 'MERCHANT_NOT_ENROLLED',
  WITH_CRYPTOGRAM = 'WITH_CRYPTOGRAM',
  WITHOUT_CRYPTOGRAM = 'WITHOUT_CRYPTOGRAM',
}

export interface VerifoneCardPaymentInputRequest {
  encryptedCard: string

  /** @min 1 */
  amount: number
  threedData: ThreeDAuthenticationRequest
  expYear: number

  /**
   * @min 1
   * @max 12
   */
  expMonth: number
  paymentId: number
  storeToken?: boolean
}

export interface VerifoneCreateJWTResponse {
  jwt: string
  publicKey: string
}

export interface VerifoneJWTLookupInputRequest {
  /** @min 0 */
  amount: number
  encryptedCard?: string
  tokenId?: string
  deviceInfoId: string
  billingFirstName: string
  billingLastName: string
  billingAddress1: string
  billingCity: string
  billingCountryCode: string

  /** @format email */
  email: string
  merchantReference: string
}

export interface VerifoneJWTLookupResponse {
  acsRenderingType: string
  acsTransactionId: string

  /** @format uri */
  acsUrl: string
  authenticationId: string
  authenticationType: string
  authorizationPayload: string
  cardholderInfo: string
  cavv: string
  cavvAlgorithm: string
  challengeRequired: string
  cardBrand: string
  dsTransactionId: string
  eciFlag: string
  enrolled: string
  errorDesc: string
  errorNo: string
  networkScore: string
  orderId: string
  paresStatus: string
  payload: string
  reasonCode: string
  reasonDesc: string
  signatureVerification: string
  statusReason: string
  thirdPartyToken: string
  threedsVersion: string
  token: string
  transactionId: string
  xid: string
}

export interface VerifonePaymentToken {
  /** @format uuid */
  id: string
  maskedCardNumber: string
  expiryMonth: number
  expiryYear: number
  brand: string
}

export interface VerifoneStoreCardInputRequest {
  encryptedCard: string
  threedData: ThreeDAuthenticationRequest

  /**
   * @min 2024
   * @max 2200
   */
  expYear: number

  /**
   * @min 1
   * @max 12
   */
  expMonth: number
}

export interface VerifoneTokenPaymentInputRequest {
  tokenId: string

  /** @min 1 */
  amount: number
  paymentId: number
}

export interface VerifoneWalletPaymentInputRequest {
  /** @min 1 */
  amount: number
  walletType: WalletTypeEnum
  walletPayload: Record<string, any>
  paymentId: number
}

export interface VirtualCard {
  /** @format uuid */
  id: string
  maskedCardNumber: string
  expMonth: number
  expYear: number
  brand: string | null

  /** @format date-time */
  created: string
}

export interface VirtualCardRequest {
  cardNumber: string
  expMonth: number
  expYear: number
  cvc: string
}

export interface VoucherSerialzer {
  code: string
  discount: string
  type?: VoucherSerialzerTypeEnum
  applyOncePerOrder?: boolean
  applyOncePerCustomer?: boolean
}

export enum VoucherSerialzerTypeEnum {
  EntireOrder = 'entire_order',
  Shipping = 'shipping',
  SpecificProduct = 'specific_product',
}

export enum WalletTypeEnum {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
}

export interface Warehouse {
  /** @format uuid */
  id?: string
  name: string

  /** @pattern ^[-\w]+$ */
  slug: string
  shippingZones?: number[]
  address: string[]
  isStore: boolean
}

export type WeekdaysEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6

export interface Wishlist {
  id: number
  channel?: string
  name?: string
  items: WishlistItem[]
}

export interface WishlistEncoderInputRequest {
  name: string
  items: WishlistEncoderItemInputRequest[]
}

export interface WishlistEncoderItemInputRequest {
  variant: WishlistEncoderVariantInputRequest
}

export interface WishlistEncoderVariantInputRequest {
  id: number
}

export interface WishlistItem {
  sku: string

  /** @format date-time */
  dtCreated: string
  variant: WishlistVariant
  wishlist: number
  comment?: string
}

export interface WishlistItemEdit {
  sku: string
  channel?: string
  variant: WishlistVariant
  wishlist?: number
  comment?: string
}

export interface WishlistItemEditRequest {
  sku: string
  channel?: string
  wishlist?: number
  comment?: string
}

export interface WishlistItemRequest {
  comment?: string
}

export interface WishlistProduct {
  id: number

  /** @pattern ^[-\w]+$ */
  slug: string
  type: string

  /** @format double */
  online: number
}

export interface WishlistProductRequest {
  /** @pattern ^[-\w]+$ */
  slug: string
}

export interface WishlistRequest {
  channel?: string
  name?: string
}

export interface WishlistVariant {
  id: number
  sku?: string | null
  name?: string
  firstImage: VariantImage
  inventoryStatus: Record<string, any>
  recommended: ElkoAttributeNavEmm
  outlet: ElkoAttributeOutlet
  banner: ElkoAttributeBanner
  product: WishlistProduct
  listings: WishlistVariantChannelListingMapping
  isInStockWeb: boolean
  isInStockDutyfree: boolean
}

export interface WishlistVariantChannelListing {
  channel: Channel
  price: ElkoPrice
}

export interface WishlistVariantChannelListingMapping {
  webshop: WishlistVariantChannelListing
  dutyfree: WishlistVariantChannelListing
}

export interface WishlistVariantRequest {
  sku?: string | null
  name?: string
  product: WishlistProductRequest
}
