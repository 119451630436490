import {VariantList, VariantDetail, Variant, WishlistVariant} from '../api/rest'
import settings from '../constants/settings'

export function getListings(productVariant: VariantDetail | VariantList) {
  return productVariant?.listings?.[settings.channel]
}

export function getListingPrice(
  productVariant?: VariantDetail | VariantList | Variant | WishlistVariant,
) {
  return productVariant?.listings?.[settings.channel]?.price
}
